<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" />
      <Steps
        :levels="approvelevel"
        v-if="approvelevel && appStatus == 1"
        :hierarchy="hierarchy"
      />
      <div class="content-show">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="基本信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="框架协议名称" :value="details.name" />
                    <van-cell
                      title="签约供应商"
                      :value="details.supplierName"
                    />
                    <van-cell title="有效期" :value="details.validityDate" />
                    <van-cell
                      title="签约单位"
                      :value="details.createCompDesc"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="具体信息" name="2" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="协议编码" :value="details.code" />
                    <van-cell title="协议类型" :value="details.typeName" />
                    <!-- <template #default>
                      <span></span>
                      </template> -->
                    <van-cell
                      title="协议目标值(元)"
                      :value="details.targetvalue"
                    />
                    <van-cell title="节约率">
                      <span
                        >{{ details.saveratio
                        }}<span v-if="details.saveratio">%</span></span
                      >
                    </van-cell>
                    <van-cell title="税率" :value="details.taxcodeName" />
                    <van-cell
                      title="签约日期"
                      :value="details.signeddateName"
                    />
                    <van-cell title="协议状态" :value="details.statusDesc" />
                    <van-cell
                      title="业务类型"
                      :value="details.isbusiness == 1 ? '保供' : '自营'"
                    />
                    <van-cell title="采购类型" :value="details.attrName" />
                    <van-cell title="采购方式" :value="details.stockName" />
                    <van-cell
                      title="ERP框架协议号"
                      :value="details.erpProjectNo"
                    />
                    <van-cell title="备注" :value="details.projectDesc" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="其他信息" name="3" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="定价机制" :value="details.pricingTypeName">
                    </van-cell>
                    <van-cell
                      title="供应份额分配原则"
                      :value="details.suppasign"
                    >
                    </van-cell>
                    <van-cell title="附件">
                      <div
                        v-for="(item, index) in details.fileVOList"
                        :key="index"
                        class="cell"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/file/FileuploadAction/' +
                              item.id +
                              '-' +
                              item.title
                          "
                          >{{ item.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="经办人信息" name="4" icon="label">
                  <van-cell-group class="group">
                    <van-cell
                      title="提报单位"
                      :value="details.createCompDesc"
                    />
                    <van-cell title="提报人" :value="details.createUserName" />
                    <van-cell title="提报人联系方式" :value="details.mobile" />
                    <van-cell
                      title="提报日期"
                      :value="details.createdateName"
                    />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="物资信息" name="b">
            <van-list :finished="true" finished-text="没有更多了">
              <div
                class="supplies"
                v-for="(item, index) in details.agreementProductsVOs"
                :key="index"
              >
                <div class="supplies-title">{{ item.productDesc }}</div>
                <div class="supplies-text">物资编码:{{ item.strprodcode }}</div>
                <div class="supplies-text">
                  数量:{{ item.quantity }}{{ item.strMeasUnit }}
                </div>
                <div class="supplies-text">单价(元):{{ item.price }}</div>
                <div class="supplies-text">税率:{{ item.taxRate }}</div>
              </div>
            </van-list>
          </van-tab>
          <van-tab title="执行企业信息" name="c"
            ><van-list :finished="true" finished-text="没有更多了">
              <div
                class="supplies"
                v-for="(item, index) in details.agreemencorpVOList"
                :key="index"
              >
                <div class="supplies-title">{{ item.strcorpname }}</div>
                <div class="supplies-text">
                  ERP工厂: {{ item.erpFactoryName }}
                </div>
                <div class="supplies-text">
                  ERP采购组: {{ item.erpGrpName }}
                </div>
                <div class="supplies-text">
                  企业协议总值(元): {{ item.agTotalvalue }}
                </div>
              </div>
            </van-list></van-tab
          >
          <van-tab title="审批记录" name="c" v-if="appStatus != 1">
            <div
              class="record"
              v-for="(item, index) in details.wfapproveinfoVOS"
              :key="index"
            >
              <van-cell-group>
                <van-cell title="审批层级" :value="item.node"></van-cell>
                <van-cell title="审批节点" :value="item.nodename"></van-cell>
                <van-cell title="审批人" :value="item.userName"></van-cell>
                <van-cell title="审批时间" :value="item.enddatetime"></van-cell>
                <van-cell title="审批结论" :value="item.resultType"></van-cell>
                <van-cell
                  title="审批意见"
                  :value="item.result"
                  v-if="item.resultType == '不同意'"
                ></van-cell>
              </van-cell-group>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: true,
      submitflag: false,
      show: true,
      showage: true,
      title: "框架协议审批详情",
      approvelevel: null,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      numberrows: "2",
      projectlist: [],
      hierarchy: "",
      basicTop: {
        data: [],
        columns: [
          {
            prop: "stockName",
            label: "采购方式",
          },
          {
            prop: "supplierName",
            label: "签约供应商",
          },
          {
            prop: "typeName",
            label: "协议类型",
          },
          {
            prop: "code",
            label: "协议编码",
          },
          {
            prop: "validityDate",
            label: "有效期",
          },
        ],
      },
    };
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  created() {
    this.selectAgreementViewInfos();
  },
  methods: {
    async selectAgreementViewInfos() {
      let data = await this.$api.ec.selectAgreementViewInfos(
        this.stockaffirmId
      );
      if (data) {
        this.loadingfalg = false;
      }
      this.details = data;
      this.basicTop.data = data;
      if (this.details.fileVOList) {
        this.details.fileVOList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
      data.wfnodeVOs.map((item, index) => {
        if (item.isNodeaction == 1) {
          this.approvelevel = index + 1;
          return;
        }
      });
      this.hierarchy = data.wfnodeVOs.length;
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({
          name: "eccompany",
        });
      } else {
        this.showage = true;
        this.title = "框架协议审批详情";
      }
    },
    ondown(item) {
      let query = {
        id: item.id,
      };
      this.$router.push({ name: "preview", query });
    },
    // onRefresh() {
    //   this.loading = true;
    // },
    // onLoad() {
    //   this.Projectlist();
    // },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          agreementId: this.stockaffirmId,
          auditResult: 0,
          approveOpinion: message,
        };
      } else {
        params = {
          agreementId: this.stockaffirmId,
          auditResult: 1,
          approveOpinion: "同意",
        };
      }
      let data = await this.$api.ec.frameworkAgreementApprove(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "eccompany",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.record {
  margin-top: 10px;
}
</style>
